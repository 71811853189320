<template>
  <div @click="toggleDarkmode" class="darkmode-toggle" :class="{ dark: $store.state.Index.isDarkMode }">
    <span>{{ $store.state.Index.isDarkMode ? 'Light mode' : 'Dark mode' }}</span>
    <font-awesome-icon
        :icon="['fal', $store.state.Index.isDarkMode ? 'sun' : 'moon']"
        style="font-size: 18px;"
    />
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DarkModeToggle",
  data() {
    return {

    }
  },
  computed: {
    ...mapState('Index', [
      'isMobile'
    ])
  },
  methods: {
    async toggleDarkmode() {
      const currentMode = localStorage.getItem('dark');
      localStorage.setItem('dark', !currentMode || currentMode === 'false');
      this.$store.dispatch('Index/setDarkModeStatus', !currentMode || currentMode === 'false');
    }
  }
}
</script>

<style lang="scss" scoped>

.darkmode-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  transition: .3s;
  cursor: pointer;
  background: transparent;

  &:hover {
    color: #768eba !important;
  }
  &.dark {
    &:hover {
      color: #768eba !important;
    }
  }
}

</style>