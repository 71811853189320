<i18n>
{
  "en": {
    "menu": {
      "dashboard": "Dashboard",
      "exchange": "Exchange",
      "buycrypto": "Buy Crypto",
      "markets": "Markets",
      "faq": "F.A.Q.",
      "nft": "NFT"
    },
    "signIn": "Sign In",
    "signUp": "Sign Up",
    "dashboard": "Dashboard",
    "wallets": "Wallets",
    "orders": "Orders",
    "history": "Funds History",
    "referral": "Referral & Promo",
    "staking": "Staking",
    "tickets": "Support Tickets",
    "settings": "Settings"
  },
  "zh": {
    "menu": {
      "dashboard": "儀表板",
      "exchange": "交換",
      "buycrypto": "购买加密货币",
      "markets": "市場",
      "faq": "F.A.Q.",
      "nft": "NFT"
    },
    "signIn": "登录",
    "signUp": "注册",
    "dashboard": "仪表板",
    "wallets": "钱包",
    "orders": "订单",
    "history": "资金历史",
    "referral": "转介",
    "staking": " 質押",
    "tickets": "支持票",
    "settings": "设置"
  },
  "kr": {
    "menu": {
      "dashboard": "계기반",
      "exchange": "교환",
      "buycrypto": "암호화폐 구매",
      "markets": "시장",
      "faq": "F.A.Q.",
      "nft": "NFT"
    },
    "signIn": "로그인",
    "signUp": "가입하기",
    "dashboard": "계기반",
    "wallets": "지갑",
    "orders": "무역 역사",
    "history": "자금 내역",
    "referral": "추천 시스템",
    "staking": "스테이킹",
    "tickets": "지원 티켓",
    "settings": "설정"
  }
}
</i18n>

<template>
  <nav class="Navbar" :class="theme" v-if="$store.state.Index.customPageSettings.defaultNavbar">
    <article v-show="!noLogo">
      <img style="margin-right: 10px;" :src="$store.state.Index.logo" alt="" />
      <router-link :class="theme" class="logo-name" :to="$linkCreator($i18n.locale, '/')">{{ websiteName.short }}</router-link>
    </article>

    <div class="desktop" v-if="!isMobile">
      <ul style="margin-left: auto;">
        <li v-for="link, index in filteredMenu" :key="index">
          <router-link
              exact
              exact-active-class="active"
              :to="link[1].disabled ? '#' : $linkCreator($i18n.locale, link[1].link)"
              :class="{ disabled: link[1].disabled }"
          >
            {{ $t('menu')[link[0]] }} <span class="badge badge-warning" v-show="link[1].disabled">Soon</span>
          </router-link>
        </li>
      </ul>
      <div style="margin-left: auto;">
        <NavbarUserMenu :class="theme" v-if="isAuthed" />
        <ul v-else>
          <li>
            <router-link exact exact-active-class="active" :to="$linkCreator($i18n.locale, 'auth')">{{ $t('signIn') }}</router-link>
          </li>
          <li>
            <router-link exact exact-active-class="active" :to="$linkCreator($i18n.locale, 'sign-up')">{{ $t('signUp') }}</router-link>
          </li>
        </ul>
        <LocaleSwitcher :class="theme" />
      </div>
    </div>

    <div class="mobile" v-else>
      <div>
        <input class="hamburger-input" v-model="isHamburgerClicked" type="checkbox" />
        <label class="hamburger-button" :class="{ opened: isHamburgerClicked }" @click="isHamburgerClicked = !isHamburgerClicked">
          <span></span>
        </label>
      </div>
      <div class="menu" v-show="isHamburgerClicked">
        <div class="section">
          <NavbarUserMenu :class="theme" v-if="isAuthed" />
          <ul v-else>
            <li>
              <router-link exact exact-active-class="active" :to="$linkCreator($i18n.locale, 'auth')">{{ $t('signIn') }}</router-link>
            </li>
            <li>
              <router-link exact exact-active-class="active" :to="$linkCreator($i18n.locale, 'sign-up')">{{ $t('signUp') }}</router-link>
            </li>
          </ul>
        </div>
        <div class="section" v-if="$store.state.Index.isAuthed">
          <ul>
            <li v-for="link, index in userMenu" :key="'user menu' + index">
              <router-link
                  tag="div"
                  exact
                  exact-active-class="active"
                  :to="$linkCreator($i18n.locale, link)"
              >
                {{ $t(index) }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="section">
          <ul>
            <li v-for="link, index in filteredMenu" :key="index">
              <router-link
                  tag="div"
                  exact
                  exact-active-class="active"
                  :to="link[1].disabled ? '#' : $linkCreator($i18n.locale, link[1].link)"
                  :class="{ disabled: link[1].disabled }"
              >
                {{ $t('menu')[link[0]] }} <span class="badge badge-warning" v-show="link[1].disabled">Soon</span>
              </router-link>
            </li>
          </ul>
          <DarkModeToggle />
        </div>
        <div class="section">
          <LocaleSwitcher :class="theme" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "@/plugins/axios";
import {mapState} from "vuex";
import LocaleSwitcher from "@/components/main/LocaleSwitcher";
import NavbarUserMenu from "@/components/main/NavbarUserMenu";
import DarkModeToggle from "@/components/main/DarkModeToggle";

export default {
  name: "Navbar",
  components: {
    LocaleSwitcher,
    NavbarUserMenu,
    DarkModeToggle
  },
  props: {
    noLogo: Boolean,
    theme: String
  },
  data() {
    return {
      isHamburgerClicked: false,
      scrollPosition: null,
      menu: {
        dashboard: {
          link: 'dashboard',
          forAuthed: true
        },
        exchange: {
          link: 'exchange/BTC_USDT',
          forAuthed: false
        },
        buycrypto: {
          link: 'buy-crypto',
          forAuthed: false
        },
        markets: {
          link: 'markets',
          forAuthed: false
        },
        faq: {
          link: 'faq',
          forAuthed: false
        }
      },
      userMenu: {
        wallets: 'dashboard/wallets',
        orders: 'dashboard/orders',
        history: 'dashboard/history',
        referral: 'dashboard/referral',
        staking: 'dashboard/staking',
        tickets: 'dashboard/tickets',
        settings: 'dashboard/settings/security',
      }
    }
  },
  watch:{
    $route (to, from){
      if (this.isMobile) this.isHamburgerClicked = false;
    }
  },
  computed: {
    ...mapState('Index', [
      'isAuthed',
      'userInfo',
      'websiteName',
      'isMobile'
    ]),
    filteredMenu() {
      return this.isAuthed ? Object.entries(this.menu) : Object.entries(this.menu).filter(x => !x[1].forAuthed)
    }
  },
  mounted() {
    window.addEventListener('scroll', () => this.scrollPosition = window.scrollY);
  }
}
</script>

<style lang="scss" scoped>
.Navbar.mains {
  background:  #04091c;
  color: rgb(216, 214, 211);
  border-color: #363b3d !important;

  a {
    color: rgb(216, 214, 211);
  }

  .mobile .menu {
    background: #0D151D;
    color: rgb(216, 214, 211);
  }
}

.Navbar.dark {
  background: #1c1e1f;
  color: rgb(216, 214, 211);
  border-color: #363b3d !important;

  .mobile .menu {
    background: #1c1e1f;
    color: rgb(216, 214, 211);
  }
}

.Navbar {
  top: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  color: black;
  align-items: center;
  transition: .3s;
  position: sticky;
  border-bottom: 1px solid #edf0f5;
  background: #FFFFFF;
}

.Navbar article {
  display: flex;
  align-items: center;
  margin: 0 10px;
  text-transform: capitalize;
  font-size: 19px;
  padding: 14px;
}

.Navbar article img {
  height: 32px;
  margin-right: 5px;
}

.Navbar article a {
  text-decoration: none;
}

.Navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Navbar ul li {
  margin: 0 15px;
  height: 32px;
  display: flex;
  align-items: center;
}

.Navbar ul li a {
  text-decoration: none;
  transition: all .2s ease;
}

.Navbar ul li a:hover {
  color: #2A85FF !important;
}

.Navbar ul li a:visited {
  text-decoration: none;
}

.Navbar ul li a.active {
  color: #2A85FF !important;
}

.Navbar ul li a.disabled, .Navbar ul li div.disabled {
  color: #bbb4b4 !important;
}

.Navbar .desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Navbar .desktop div {
  display: flex;
  align-items: center;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hamburger-input {
  opacity: 0;
}

.hamburger-input:checked ~ .hamburger-button > span {
  transform: rotate(45deg);
}
.hamburger-input:checked ~ .hamburger-button > span::before {
  top: 0;
  transform: rotate(0);
}
.hamburger-input:checked ~ .hamburger-button > span::after {
  top: 0;
  transform: rotate(90deg);
}

.hamburger-button {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 4000;
  width: 26px;
  height: 26px;
  cursor: pointer;

  &.opened {
    position: fixed;
  }
}

.hamburger-button > span,
.hamburger-button > span::before,
.hamburger-button > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: .25s;
}
.hamburger-button > span::before {
  content: '';
  top: -8px;
}
.hamburger-button > span::after {
  content: '';
  top: 8px;
}

.mobile .menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3999;
  height: 100vh;
  width: 280px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 2px 32px 0 rgba(0,0,0,0.15);
  padding: 70px 20px 0;
  -webkit-animation: slideIn .2s cubic-bezier(.65,.05,.36,1) forwards;
  animation: slideIn .2s cubic-bezier(.65,.05,.36,1) forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.mobile .section {
  padding: 10px 0 10px;
  border-bottom: 1px solid #bebebe;
}

.mobile .section:first-child {
  padding: 10px 0 20px;
}

.mobile .section:last-child {
  padding: 20px 0 10px;
  border-bottom: none;
}

.mobile .section ul {
  display: block;
}

.mobile {
  color: black !important;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  font-size: 8px;
  position: relative;
  top: -5px;
  left: 2px;
}

</style>