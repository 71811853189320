<i18n>
{
  "en": {
    "estimatedLabel": "Estimated Asset Value",
    "deposit": "Deposit",
    "withdraw": "Withdraw",
    "dashboard": "Account",
    "assets": "Assets",
    "fundsHistory": "Funds History",
    "tradeHistory": "Trade History",
    "referralButton": "Earn 70% Commission",
    "logout": "Sign out"
  },
  "zh": {
    "estimatedLabel": "估计资产价值",
    "deposit": "订金",
    "withdraw": "提款",
    "dashboard": "帐户",
    "assets": "资产",
    "fundsHistory": "资金历史",
    "tradeHistory": "订单历史",
    "referralButton": "赚取70％的佣金",
    "logout": "登出"
  },
  "kr": {
    "estimatedLabel": "추정 자산 가치",
    "deposit": "예금",
    "withdraw": "인출",
    "dashboard": "계정",
    "assets": "자산",
    "fundsHistory": "자금 내역",
    "tradeHistory": "무역 역사",
    "referralButton": "70 % 수수료를받는 방법",
    "logout": "로그 아웃"
  }
}
</i18n>

<template>
  <div
      @mouseover="isMenuOpened = true"
      @mouseleave="isMenuOpened = false"
      class="user-menu-desktop"
      v-if="!isMobile"
  >
    <div
        :class="{ hovered: isMenuOpened }"
        class="header"
    >
      <router-link class="display-link" :to="$linkCreator($i18n.locale, 'dashboard')">
        {{ userInfo.email }}
      </router-link>
      <font-awesome-icon
          icon="chevron-down"
          class="select-arrow"
          size="xs"
          style="margin-left: 5px;"
      />
    </div>
    <transition name="fade">
      <div class="body" v-show="isMenuOpened">
        <div class="asset-block">
          <p class="estimated-label">
            <span>{{ $t('estimatedLabel') }}</span>
            <font-awesome-icon
                :icon="isEstimatedHidden ? 'eye-slash' : 'eye'"
                style="margin-left: 10px; cursor: pointer;"
                @click="changeEstimatedVisibleState"
            />
          </p>
          <div class="estimated-value">
            <em>{{ estimatedValueBTC }} BTC</em>
            <b>{{ estimatedValueUSD }}</b>
          </div>
          <div class="balance-link-box">
            <router-link :to="$linkCreator($i18n.locale, 'dashboard/wallets/deposit')">
              <img src="/deposit.svg">
              {{ $t('deposit') }}
            </router-link>
            <router-link :to="$linkCreator($i18n.locale, 'dashboard/wallets/withdraw')">
              <img src="/withdraw.svg">
              {{ $t('withdraw') }}
            </router-link>
          </div>
        </div>

        <div class="navigation-block">
          <div class="navigation-list">
            <router-link :to="$linkCreator($i18n.locale, linkObject.link)" v-for="linkObject, index in userLinks" :key="index">
              {{ $t(linkObject.title) }}
            </router-link>
          </div>

          <DarkModeToggle />

          <div class="referral-button">
            <router-link tag="button" :to="$linkCreator($i18n.locale, 'dashboard/referral')">
              {{ $t('referralButton') }}
            </router-link>
          </div>
          <div class="logout-button">
            <span @click="logoutHandler">
              {{ $t('logout') }}
            </span>
          </div>
        </div>

      </div>
    </transition>
  </div>

  <div class="user-menu-mobile" v-else style="background: transparent !important;">
    <div>
      <router-link tag="span" :to="$linkCreator($i18n.locale, 'dashboard')">
        {{ userInfo.email }}
      </router-link>
      <font-awesome-icon
          :icon="['fal', 'sign-out']"
          style="margin-left: 5px;"
          size="xs"
          @click="logoutHandler"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import axios from "@/plugins/axios";
import DarkModeToggle from "@/components/main/DarkModeToggle";

export default {
  name: "NavbarUserMenu",
  components: {
    DarkModeToggle
  },
  data() {
    return {
      isMenuOpened: false,
      isEstimatedHidden: false,
      userLinks: [
        {
          title: 'dashboard',
          link: 'dashboard'
        },
        {
          title: 'assets',
          link: 'dashboard/wallets'
        },
        {
          title: 'fundsHistory',
          link: 'dashboard/history'
        },
        {
          title: 'tradeHistory',
          link: 'dashboard/orders'
        },
      ]
    }
  },
  methods: {
    async logoutHandler() {
      const response = await axios.post('/main/user/logout');
      if (!response.data?.error) return location.href = "/";
      this.$callNotification(
          this.$i18n.locale,
          response
      );
    },
    changeEstimatedVisibleState() {
      this.isEstimatedHidden = !this.isEstimatedHidden;
      localStorage.setItem("isEstimatedHidden", this.isEstimatedHidden);
    }
  },
  computed: {
    ...mapState('Index', [
        'userInfo',
        'isMobile'
    ]),
    estimatedValueBTC() {
      return this.isEstimatedHidden
        ? "******"
        : this.userInfo.totalBalance.btc.toFixed(8);
    },
    estimatedValueUSD() {
      return this.isEstimatedHidden
          ? "******"
          : new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'code'
          }).format(this.userInfo.totalBalance.usd);
    },
  },
  mounted() {
    this.isEstimatedHidden = localStorage.getItem("isEstimatedHidden") === 'true' ? true : false;
  }
}
</script>

<style lang="scss" scoped>

.user-menu-desktop.mains {
  .header { border-color: #363b3d !important; }
  .body { background: #0D151D; color: rgb(216, 214, 211); }
  .body .asset-block .balance-link-box a { color: rgb(216, 214, 211); }
  .body .asset-block .estimated-value em { color: rgb(216, 214, 211); }
  .body .asset-block .balance-link-box img {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(19deg) brightness(103%) contrast(101%);
  }
}

.user-menu-desktop.dark {
  background: #1c1e1f;
  .header { border-color: #363b3d !important; }
  .body { background: #1c1e1f; color: rgb(216, 214, 211); }
  .body .asset-block .balance-link-box a { color: rgb(216, 214, 211); }
  .body .asset-block .estimated-value em { color: rgb(216, 214, 211); }
  .body .asset-block .balance-link-box img {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(19deg) brightness(103%) contrast(101%);
  }
  .body .asset-block {
    border-bottom-color: rgb(58, 61, 64);
  }
}

.user-menu-desktop {
  padding: 0 15px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  color: inherit;
  height: 60px;
  z-index: 999;
}

.user-menu-desktop .header {
  padding: 12px 16px;
  border: 1px solid #edf0f5;
  white-space: nowrap;
  transition: all .3s ease-out;
  cursor: pointer;
  border-radius: 10px;
}

.user-menu-desktop .header .display-link {
  text-decoration: none;
  color: inherit;
}

.user-menu-desktop .header .select-arrow {
  transition: inherit;
}

.user-menu-desktop .header.hovered .select-arrow {
  transform: rotate(180deg);
}

.user-menu-desktop .header.hovered, .user-menu-desktop .header ~ svg {
  color: #2A85FF;
}

.user-menu-desktop .header.hovered {
  border-color: #2A85FF;
}

.user-menu-desktop .body {
  position: absolute;
  box-sizing: border-box;
  top: 60px;
  width: 320px;
  right: 0;
  margin-right: 15px;
  background-color: white;
  box-shadow: 0 4px 12px 0 rgba(0, 1, 4, 0.1);
  font-size: 14px;
  color: #0e1118;
}

.user-menu-desktop .body .asset-block {
  padding: 32px 32px 24px;
  border-bottom: 1px solid #e0e0e0;
}

.user-menu-desktop .body .asset-block .estimated-label {
  margin-bottom: 14px;
  color: #888;
  font-size: 14px;
}

.user-menu-desktop .body .asset-block .estimated-value em {
  display: block;
  font-weight: 500;
  margin-right: 8px;
  font-size: 24px;
  font-style: normal;
  color: #111;
}

.user-menu-desktop .body .asset-block .estimated-value b {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #888;
  margin-top: 4px;
}

.user-menu-desktop .body .asset-block .balance-link-box {
  margin-top: 24px;
  font-size: 14px;
}

.user-menu-desktop .body .asset-block .balance-link-box img {
  margin-right: 2px;
}

.user-menu-desktop .body .asset-block .balance-link-box a:hover, .user-menu-desktop .body .navigation-block .navigation-list a:hover, .user-menu-desktop .body .navigation-block .logout-button span:hover {
  color: #2A85FF;
}

.user-menu-desktop .body .asset-block .balance-link-box a {
  margin-right: 22px;
  transition: color .2s ease;
  color: black;
  text-decoration: none;
}

.user-menu-desktop .body .asset-block .navigation-list div {
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  transition: color .2s ease;
}

.user-menu-desktop .body .navigation-block {
  padding: 32px 32px 24px;
}

.user-menu-desktop .body .navigation-block .navigation-list a {
  display: block;
  padding-bottom: 20px;
  color: inherit;
  text-decoration: inherit;
}

.user-menu-desktop .body .navigation-block .referral-button {
  text-align: center;
}

.user-menu-desktop .body .navigation-block .referral-button button {
  display: block;
  margin: 15px auto 30px;
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(
      270deg
      , #2A85FF, #2b2a83);
  color: #fff;
  font-size: 16px;
  box-shadow: 0 4px 12px 0 rgba(0, 1, 4, 0.1);
  outline: none;
  cursor: pointer;
}

.user-menu-desktop .body .navigation-block .logout-button {
  text-align: center;
}

.user-menu-desktop .body .navigation-block .logout-button span {
  width: 100%;
  background: none;
  border: 0;
  font-size: 14px;
  color: #646464;
  cursor: pointer;
  transition: color .2s ease;
}

</style>